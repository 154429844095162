.chatlline {
    line-height: 0px;
    padding: 0px;
}

.username {
    color: #2ad9e6;
    text-decoration: bold;
    display: inline;
}

.input {
    margin-left: 0px;
    width: calc(100% - 60px);
    margin-top: 1px
}

h2 {
    text-align: center;
}

form {
    text-align: left;
}

.chatbox {
    text-align: left;
    border: double 3px;
    border-color: chartreuse;
    padding-top: 20px;
    width: 99%;
    height: 250px;
    overflow-y: auto;
    display: flex;
    flex-direction: column-reverse;
    word-wrap: break-word;
}

::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
}

::-webkit-scrollbar-thumb {
    background: #16d60f;
    border: 3px none #00ff4c;
    border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
    background: #01ff22;
}

::-webkit-scrollbar-thumb:active {
    background: #000000;
}

::-webkit-scrollbar-track {
    background: #121212;
    border: 0px none #ffffff;
    border-radius: 55px;
}

::-webkit-scrollbar-track:hover {
    background: #353535;
}

::-webkit-scrollbar-track:active {
    background: #00fb38;
}

::-webkit-scrollbar-corner {
    background: transparent;
}
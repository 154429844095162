.App {
  background-image: url("./bg.png");
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding-right: 20%;
  padding-left: 20%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  z-index: -1;
  background-attachment: fixed;
  /* Remove this line if it's not going to be a background! */
  font-family: 'Rajdhani', sans-serif;
  /* font-family: 'News Cycle', sans-serif; */
  line-height: 18px;
  cursor: context-menu
}

body {
  overflow: hidden;
}

.banner {
  position: relative;
  top: 10px;
  flex-grow: 1;
  line-height: 5px;
  color: #1fe729;
  border: 1px solid;
  border-color: #00dd11;
  background-color: rgba(0, 0, 0, 0.2);
  height: 30px;
  max-width: 900px;
  padding: 0px 10px 12px 10px;
}

.mid {
  margin-left: 10px;
  flex-direction: column;
  flex-grow: 10;
}

h1 {
  text-decoration: underline;
  font-size: 16px;
  padding: 5px;
  margin: 0px;
}

h2 {
  text-decoration: underline;
  font-size: 24px;
  padding: 5px;
  margin: 5px;
}

hr {
  border: 1px solid lime;
  border-radius: 1px;
  width: 100%;
}

img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px;
}

a {
  color: #01b7ff;
  font-family: 'Rajdhani', sans-serif;
  text-decoration: none;
  display: inline
}

a:visited {
  color: #01b7ff;
}

a:hover {
  color: #09d3ac;
}

p {
  padding: 0px;
  margin: 0px;
}

input {
  background-color: #141414;
  margin-left: 10px;
  width: 170px;
  background-color: #000000;
  border: 1px solid;
  border-color: #00FF00;
  color: #bebebe;
}

button {
  color: #01b7ff;
  cursor: pointer;
  background-color: #141414;
  border: 1px solid;
  border-color: #00FF00;
  -webkit-transition-duration: 0.3s;
  /* Safari */
  transition-duration: 0.3s;
}

button:hover {
  color: #01b7ff;
  background-color: #32b7d8;
  border: 1px solid;
  border-color: #00FF00
}

button:hover {
  color: #010e13;
  background-color: #2585df;
  border: 1px solid;
  border-color: #00FF00
}

P5Wrapper {
  position: absolute;
  top: 400px;
  left: 50% translate(50%);
}
.e404 {
  width:200px;
  height:200px;
  border: 1px solid;
  border-color:#00FF00;
}
.view {
  margin-left: 200px auto;
  position: relative;
  margin-top: 12px;
  padding: 10px 10px 10px 10px;
  display: block;
  text-align: center;
  color: #1fe729;
  border: 1px solid;
  border-color: #00dd11;
  background-color: rgba(0, 0, 0, 0.2);
  max-width: 900px;
}

.column {
  margin: 0px;
  padding: 8px 6px 8px 8px;
  position: relative;
  flex-grow: 0;
  color: #1fe729;
  border: 1px solid;
  border-color: #00dd11;
  background-color: rgba(0, 0, 0, 0.2);
  top: 10px;
  left: 10px;
  width: 140px;
  max-height: 480px;
  min-width: 150px;
}

.vert {
  writing-mode: vertical-rl;
  color: #00FF00;
  margin-top: 20px;
  margin-left: 20px;
  top: 400px;
  left: 400px;
  line-height: 0px;
}

label {
  display: block;
  margin-bottom: 3px;
}

form {
  width: 100%;
}

.center {
  text-align: center;
}

.test {
  color: #f1fbce
}

.notification {
  display: none;
  width: 300px;
  height: 100px;
  overflow: hidden;
  position: absolute;
  bottom: 100px;
  margin-left: 50%;
  margin-right: 50%;
  border: 1px solid;
  border-color: chartreuse;
  background-color: black;
  color: #00ff00;
  transition: 0.2s ease-out
}

.Modal {
  position: absolute;
  z-index: 200;
  border: 1px solid #00ff00;
  color: lime;
  background-color: black;
  padding: 10px;
  text-align: center;
  top: 10px;
  max-width: 900px;
  transition: all 0.3s ease-out;
  transform: translateX(-50%);
}

.ModalOpen {
  opacity: 1;
  transform: translateY(0);
}

.ModalClosed {
  opacity: 0;
  transform: translateY(-120%);
}